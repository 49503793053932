<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        label="Grupo para los Ciudadanos"
        v-model="setting.grupo_ciudadano_id.valor"
        :items="groups"
        item-value="id"
        item-text="name"
        outlined
        dense
      />
    </v-col>
  </v-row>
</template>

<script>
import GroupService from '@/services/group'

export default {
  props: {
    setting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      groups: []
    }
  },
  async created() {
    await this.populateGroups()
  },
  methods: {
    async populateGroups() {
      const { data } = await GroupService.fetch({
        'filter[usuario_sistema]': '0'
      })
      this.groups = data.data
    }
  }
}
</script>

<style></style>
