var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('BaseList',{attrs:{"color":"primary","title":_vm.$route.meta.title,"section":_vm.$route.meta.section,"headers":_vm.headers,"items":_vm.activities,"loading":_vm.loading,"totalRows":_vm.totalRows,"buttons":{ search: true },"actions":[]},on:{"add":_vm.handleNew,"edit":_vm.handleEdit,"refresh":_vm.getData,"delete":_vm.handleDelete,"search":_vm.handleSearch,"options":_vm.handleOptions},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.causer_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user ? ((item.user.first_name) + " " + (item.user.last_name)) : ''))])]}},{key:"item.properties",fn:function(ref){
var value = ref.value;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-expansion-panels',{staticClass:"expansion-panel",attrs:{"focusable":"","accordion":"","elevation":"0","hover":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"panel-header",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-plus")])]},proxy:true}],null,true)},[_vm._v(" Ver Detalle ")]),_c('v-expansion-panel-content',[(value.old)?_c('span',[_c('br'),_c('h4',[_vm._v("Registro Viejo")]),_c('br'),_c('ul',_vm._l((_vm.getValues(value.old)),function(i){return _c('li',{key:i},[_vm._v(" "+_vm._s(i)+" ")])}),0)]):_vm._e(),_c('br'),_c('h4',[_vm._v("Registro:")]),_c('br'),_c('ul',_vm._l((_vm.getValues(value.attributes)),function(i){return _c('li',{key:i},[_vm._v(" "+_vm._s(i)+" ")])}),0)])],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"panel-header"},'v-btn',attrs,false),on),[_vm._v("Ver Detalle")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Propiedades")]),_c('v-card-text',[(value.old)?_c('span',[_c('br'),_c('h4',[_vm._v("Registro Viejo")]),_c('br'),_c('ul',_vm._l((_vm.getValues(value.old)),function(i){return _c('li',{key:i},[_vm._v(" "+_vm._s(i)+" ")])}),0)]):_vm._e(),_c('br'),_c('h4',[_vm._v("Registro:")]),_c('br'),_c('ul',_vm._l((_vm.getValues(value.attributes)),function(i){return _c('li',{key:i},[_vm._v(" "+_vm._s(i)+" ")])}),0)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cerrar")])],1)],1)]}}],null,true)}):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(value,'DD/MM/YY - hh:mm:ss a')))])]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(value,'DD/MM/YY - hh:mm:ss a')))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }