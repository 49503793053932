<template>
  <v-container>
    <BaseList
      color="primary"
      :title="$route.meta.title"
      :section="$route.meta.section"
      :headers="headers"
      :items="activities"
      :loading="loading"
      :totalRows="totalRows"
      :buttons="{ search: true }"
      :actions="[]"
      @add="handleNew"
      @edit="handleEdit"
      @refresh="getData"
      @delete="handleDelete"
      @search="handleSearch"
      @options="handleOptions"
    >
      <template #item.description="{ item }">
        <span>
          {{ item.description }}
        </span>
      </template>
      <!-- UPDATED AT FIELD -->
      <template #item.causer_id="{ item }">
        <span>{{
          item.user ? `${item.user.first_name} ${item.user.last_name}` : ''
        }}</span>
      </template>
      <!-- Propiedades -->
      <template #item.properties="{ value }">
        <v-expansion-panels
          focusable
          accordion
          elevation="0"
          hover
          class="expansion-panel"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="panel-header">
              Ver Detalle
              <template v-slot:actions>
                <v-icon color="primary"> mdi-plus</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span v-if="value.old">
                <br />
                <h4>Registro Viejo</h4>
                <br />
                <ul>
                  <li v-for="i in getValues(value.old)" :key="i">
                    {{ i }}
                  </li>
                </ul>
              </span>
              <br />
              <h4>Registro:</h4>
              <br />
              <ul>
                <li v-for="i in getValues(value.attributes)" :key="i">
                  {{ i }}
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="300"
          v-if="$vuetify.breakpoint.smAndDown"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="panel-header" v-bind="attrs" v-on="on"
              >Ver Detalle</v-btn
            >
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark>Propiedades</v-toolbar>
              <v-card-text>
                <span v-if="value.old">
                  <br />
                  <h4>Registro Viejo</h4>
                  <br />
                  <ul>
                    <li v-for="i in getValues(value.old)" :key="i">
                      {{ i }}
                    </li>
                  </ul>
                </span>
                <br />
                <h4>Registro:</h4>
                <br />
                <ul>
                  <li v-for="i in getValues(value.attributes)" :key="i">
                    {{ i }}
                  </li>
                </ul>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
      <!-- UPDATED AT FIELD -->
      <template #item.updated_at="{ value }">
        <span>{{ value | date('DD/MM/YY - hh:mm:ss a') }}</span>
      </template>
      <!-- CREATE AT FIELD -->
      <template #item.created_at="{ value }">
        <span>{{ value | date('DD/MM/YY - hh:mm:ss a') }}</span>
      </template>
    </BaseList>
  </v-container>
</template>

<script>
import BaseList from '@/components/commons/BaseList'
import ActivityLogService from '@/services/activityLog'

export default {
  components: { BaseList },
  data() {
    return {
      options: {},
      loading: false,
      activities: [],
      totalRows: 0,
      search: '',
      headers: [
        { text: 'Descripción', value: 'description' },
        { text: 'Propiedades', value: 'properties', width: '30vw' },
        { text: 'Usuario', value: 'causer_id' },
        { text: 'Fecha de actividad', value: 'created_at', width: '12rem' }
      ]
    }
  },
  methods: {
    async getData() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.options

      this.loading = true
      try {
        const { data } = await ActivityLogService.fetch(
          { 'filter[query]': this.search, include: 'user' },
          sortBy[0] ? sortBy[0] : '-created_at',
          sortDesc[0],
          page,
          itemsPerPage == -1 ? this.totalRows : itemsPerPage
        )
        this.activities = data.data
        this.totalRows = data.total
      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e.message)
      } finally {
        this.loading = false
      }
    },

    handleNew() {
      this.$router.push({ name: 'GenderIdentityCreate' })
    },

    handleEdit(item) {
      this.$router.push({
        name: 'GenderIdentityEdit',
        params: { id: item.id }
      })
    },

    async handleDelete(item) {
      const res = await this.$dialog.warning({
        text: '¿ Desea eliminar el registro ?'
      })

      if (!res) return false

      try {
        await ActivityLogService.delete(item.id)
        this.$dialog.notify.success('Registro eliminado con éxito.')
        await this.getData()
      } catch (e) {
        this.$dialog.message.error(e.title)
      }
    },

    handleOptions(options) {
      this.options = options
      this.getData()
    },

    handleSearch(value) {
      this.search = value
      this.getData()
    },

    getValues(value) {
      delete value.created_at
      delete value.updated_at
      delete value.deleted_at

      let items = []
      Object.keys(value).forEach(el => {
        const val = `${el}: ${value[el]}`
        items.push(val)
      })

      return items
    },
    truncateIf(i) {
      if (i.length > 30) {
        let subStr = i.substring(0, 30)
        return subStr + '...'
      } else {
        return i
      }
    }
  }
}
</script>

<style scoped>
.panel-header {
  color: #001737;
  font-size: 14px;
}

tr:nth-child(odd) .v-expansion-panel-header {
  background-color: #edf2f7;
}
</style>
