<template>
  <v-row>
    <v-col cols="12" md="6">
      <ValidationProvider
        name="Archivos Soportados"
        rules="required"
        v-slot="{ errors }"
      >
        <v-combobox
          v-model="setting.quantity_questions"
          label="Cantidad de Preguntas"
          clearable
          multiple
          :items="supportsFiles"
          :error-messages="errors[0]"
          dense
          outlined
        >
        </v-combobox>
      </ValidationProvider>
    </v-col>
    <v-col cols="12" md="6">
      <ValidationProvider
        name="Tamaño máximo de archivos"
        rules="required|min_value:0.1"
        v-slot="{ errors }"
      >
        <numeric-input
          label="Tamaño máximo de archivos (Mb)"
          placeholder="Tamaño máximo permitido para los documentos."
          v-model="setting.tamano_max_archivos.valor"
          :error-messages="errors[0]"
          dense
          outlined
        />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import NumericInput from '@/components/commons/NumericInput'

export default {
  components: { NumericInput },
  props: {
    setting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      supportsFiles: ['*.png', '*.jpg', '*.pdf', '*.bmp']
    }
  }
}
</script>

<style></style>
