<template>
  <BaseForm
    :title="$route.meta.title"
    :loading="loading"
    @submit="handleSubmit"
  >
    <template v-slot:content>
      <v-tabs active-class="tab-active">
        <v-tab>
          General
        </v-tab>
        <v-tab>
          Grupos y Permisos
        </v-tab>

        <v-tab-item>
          <general class="my-6" :setting="setting" />
        </v-tab-item>
        <v-tab-item>
          <group class="my-6" :setting="setting" />
        </v-tab-item>
      </v-tabs>
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import SettingService from '@/services/setting'

import General from './partials/General'
import Group from './partials/Groups'

export default {
  components: { BaseForm, Group, General },
  data() {
    return {
      loading: false,
      settings: [],
      setting: {
        quantity_questions: null,
      }
    }
  },
  async created() {
    this.loading = true
    try {
      await this.populateSettings()
      await this.populateGlobal()
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async populateSettings() {
      const { data } = await SettingService.fetch()
      this.settings = data.data
    },
    getValue(key) {
      return this.settings.find(item => item.nombre == key)?.valor
    },
    getSetting(key) {
      return this.settings.find(item => item.nombre == key)
    },
    populateGlobal() {
      this.setting.quantity_questions = this.getSetting('quantity_questions')
      // this.setting.tipo_archivo.valor = this.setting.tipo_archivo.valor.split(
      //   ','
      // )
      // this.setting.tamano_max_archivos = this.getSetting('tamano_max_archivos')
      // this.setting.grupo_ciudadano_id = this.getSetting('grupo_ciudadano_id')
    },

    async handleSubmit() {
      let id = null
      this.loading = true
      try {
        const supportsFiles = {
          ...this.setting.tipo_archivo,
          valor: this.setting.tipo_archivo.valor.join(',')
        }
        id = this.setting.tipo_archivo.id
        await SettingService.update(id, supportsFiles)

        id = this.setting.tamano_max_archivos.id
        await SettingService.update(id, this.setting.tamano_max_archivos)

        id = this.setting.grupo_ciudadano_id.id
        await SettingService.update(id, this.setting.grupo_ciudadano_id)

        this.$dialog.notify.success('Configuración guardada con éxito.')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style></style>
